* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /*margin: 0;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* global-styles.css */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
  margin-left: -5px;
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Roundness of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

